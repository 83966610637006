<template>
  <OnboardPage 
    :content="content"
  >
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <form @submit.prevent="onSubmit">
      <BaseSelect
        label="Choose a prefund amount"
        v-model.number="refillAmount"
        :options="content.prefund_options"
      />
      <BaseSelect
        label="Refill when balance is below"
        v-model="refillTrigger"
        :options="content.refill_options"
      />
      <p 
        v-if="refillTrigger == 'none'"
        class="has-text-danger"
        style="padding-bottom: 10px"
      >
        When balance drops below deal award value, then deal will be deactivated. Auto-refill recommended. 
      </p>
      <p
        v-else-if="refillTrigger < currentDeal.award_value"
        class="has-text-danger"
        style="padding-bottom: 10px"
      >
        We suggest auto-refilling your account when the balance is still above the deal award value to prevent deal deactivation.
      </p>
      <BaseSubmit
        :label="content.showNextLabel"
        :disabled="false"
        :submitting="submitting"
      />
    </form>
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import OnboardPage from "@/components/OnboardPage.vue";
import Notification from "@/components/Notification.vue";
import BaseSelect from "@/components/form/BaseSelect.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    OnboardPage,
    Notification,
    BaseSelect,
    BaseSubmit
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      refillAmount: 2500,
      refillTrigger: 1000,
      content: {
        page: 'organization-fund',
        step: 4,
        title: 'Prefund your deal',
        subtitle: '',
        sideComponentText: 'Attract more customers. Only pay when a customer purchases and claims the deal.',
        nextPage: "organization-payment-method",
        showNextLabel: "Next",
        showNext: true,
        showBack: true,
        prefund_options: [
          {
            value: 2500,
            label:  "$25"
          },
          {
            value: 5000,
            label:  "$50"
          },
          {
            value: 10000,
            label:  "$100"
          },
          {
            value: 25000,
            label: "$250"
          },
          {
            value: 50000,
            label: "$500"
          },
          {
            value: 100000,
            label:  "$1,000"
          },
        ],
        refill_options: [
          {
            value: 1000,
            label:  "$10"
          },
          {
            value: 3000,
            label:  "$30"
          },
          {
            value: 10000,
            label:  "$100"
          },
          {
            value: "none",
            label:  "Do not auto refill"
          },
        ],
      }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;     
    },
  },
  mounted() {
    if (this.currentBusiness && 
        this.currentBusiness.slug &&
        this.currentDeal &&
        this.currentDeal.slug) {
      
      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.selectRefillAmount()
        this.selectRefillTrigger()
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    selectRefillAmount() {
      if (this.currentDeal.award_value >= 2500 && this.currentDeal.award_value < 5000) {
        this.content.prefund_options = [{value: 5000, label: "$50"},{value: 10000, label: "$100"},{value: 25000, label: "$250"},{value: 50000,label: "$500"}, {value: 100000, label: "$1,000"}]
      } else if (this.currentDeal.award_value >= 5000 && this.currentDeal.award_value < 10000) {
        this.content.prefund_options = [{value: 10000, label: "$100"},{value: 25000, label: "$250"},{value: 50000, label: "$500"},{value: 100000, label:"$1,000"}]
      } else if (this.currentDeal.award_value >= 10000 && this.currentDeal.award_value < 25000) {
        this.content.prefund_options = [{value: 25000, label: "$250"},{value: 50000, label: "$500"},{value: 100000, label: "$1,000"}]
      } else if (this.currentDeal.award_value >= 25000 && this.currentDeal.award_value < 50000) {
        this.content.prefund_options = [{value: 50000, label: "$500"},{value: 100000, label: "$1,000"}]
      } else if (this.currentDeal.award_value >= 50000) {
        this.content.prefund_options = [{value: 100000, label:"$1,000"}]
      }
      this.refillAmount = this.currentBusiness.refill_amount
      if (!this.refillAmount || this.refillAmount < this.content.prefund_options[0]["value"]) {
        this.refillAmount = this.content.prefund_options[0]["value"]
      }
    },
    selectRefillTrigger() {
      this.refillTrigger = (!this.currentBusiness.refill_trigger) ? this.content.refill_options[0]["value"].toString() : this.currentBusiness.refill_trigger
    },
    back() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.submitting = true;
  
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: {
          refill_amount: this.refillAmount,
          refill_trigger: this.refillTrigger
        }
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.$router.push('/o/' + this.content.nextPage)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
